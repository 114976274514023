export const LocalStorageCache = {

    data: JSON.parse(localStorage.getItem('MemoryCache')),

    set(key, value, maxAge) { // Save data

        let storeData = JSON.parse(localStorage.getItem('MemoryCache'));

        let toSetData = {
            // expiration_timeout: maxAge || 60000,
            value,
            initialize_time: Date.now(),
        };
        if(!storeData){
            storeData = {};
        }
        storeData[key] = toSetData;
        // console.log('saving',storeData);
        localStorage.setItem('MemoryCache', JSON.stringify(storeData));

    },

    get(key, expirationTimeout) { // Retrieves the value of the specified key from the cache.
        let storeData = JSON.parse(localStorage.getItem('MemoryCache'));
// console.log('obj from memory', storeData, key);
        const cachedItem = (storeData && storeData[key])? storeData[key] : null;
        // console.log('obj cachedItem', cachedItem);

        if(!cachedItem) return null;

        const isExpired = Date.now() - cachedItem.initialize_time > expirationTimeout;
        // isExpired && this.delete(key);
        return isExpired ? null : cachedItem.value;
    },

    delete(key) { // Remove the value of the specified key from the cache.
        // return delete this.data[key];
        let storeData = JSON.parse(localStorage.getItem('MemoryCache'));
        if(storeData[key]){
            delete storeData[key];
        }
        localStorage.setItem('MemoryCache', JSON.stringify(storeData));
    },

    clear() { // Clear the cached data.
        // this.data = {};
        localStorage.removeItem('MemoryCache');
    }
};

export const browserCache = {
    cacheName: 'browser_cache',
    set(key, jsonResponse, maxAge) { // Save data

        // let storeData = JSON.parse(localStorage.getItem('MemoryCache'));
        // let toSetData = {
        //     maxAge: maxAge || 60000,
        //     jsonResponse,
        //     now: Date.now(),
        // };
        // if(!storeData){
        //     storeData = {};
        // }
        // storeData[key] = toSetData;
        // console.log('saving',storeData);
        // localStorage.setItem('MemoryCache', JSON.stringify(storeData));
// alert('here');
        let url = 'my_url';
        // caches.open(browserCache.cacheName).then( cache => {
        //     cache.add('https://api.marketplace-vue.loc/api/settings?items[]=site_settings').then( () => {
        //         console.log("Data cached ");
        //         caches.open(cacheName).then(cache => {
        //             cache.match(url).then(resp => {
        //                 console.log('cached resp',resp);
        //             });
        //         });
        //     });
        // });
        console.log('to save jsonResponse',jsonResponse);
        caches.open(browserCache.cacheName)
            .then(function(cache){
                cache.put(url, jsonResponse);
                caches.open(browserCache.cacheName).then(cache => {
                    console.log('cache', cache);
                    cache.match(url).then(resp => {
                        console.log('cached resp !!!',resp);
                    });
                });
            });
    },
    get(key) { // Retrieves the value of the specified key from the cache.
        let storeData = JSON.parse(localStorage.getItem('MemoryCache'));
        console.log('obj from memory', storeData, key);
        const cachedItem = storeData && storeData[key]? storeData[key] : null;
        if(! cachedItem)return null;
        const isExpired = Date.now() - cachedItem.now > cachedItem.maxAge;
        // isExpired && this.delete(key);
        return isExpired ? null : cachedItem.value;
    },
    delete(key) { // Remove the value of the specified key from the cache.
        // return delete this.data[key];
        let storeData = JSON.parse(localStorage.getItem('MemoryCache'));
        if(storeData[key]){
            delete storeData[key];
        }
        localStorage.setItem('MemoryCache', JSON.stringify(storeData));
    },
    clear() { // Clear the cached data.
        // this.data = {};
        localStorage.removeItem('MemoryCache');
    }
};