import Vue from 'vue'
import Breabcrumbs from "vue-2-breadcrumbs";

Vue.use(Breabcrumbs, {
    template: `<div v-if="$breadcrumbs.length" >

                    <ol class="breadcrumb m-0 d-inline-block">
                        <li v-show="$user.auth" class="home-breadcrumb">
                            <router-link :to="{ name: ($user.account_owner_type+'.dashboard')}"><feather-icon class="align-text-top" icon="HomeIcon" size="14"/></router-link>
                        </li>
    
                        <li v-for="(crumb, key) in $breadcrumbs"
                            v-if="crumb.meta.breadcrumb"
                            :key="key"
                            class="breadcrumb-item">
                            <router-link :to="{ path: getPath(crumb) }">{{ $t(crumb.meta.breadcrumb) }}</router-link>
                        </li>
                    </ol>
    
                </div>`
});

