var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "site--preloader", attrs: { id: "site-preloader" } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "status" } }, [
      _c("div", { staticClass: "bouncing-loader" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }