import axios from 'axios';
// import Cookies from 'js-cookie';
import {LocalStorageCache} from './cache-adapters/localStorage'
import {generateReqKey, cacheAdapterEnhancer, settle} from './cache-adapters/utils'
import {showSweetMessage} from '@resources/js/helpers/utils/partial'

const API_DOMAIN = process.env.MIX_BASE_API_URL
const defaultLang = process.env.MIX_DEFAUL_LANG;

import {getAdapter} from 'axios';

const apiOptions = {
    headers: {
        'Accept' :'application/json',

        'X-Requested-With': 'XMLHttpRequest',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
        // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
        // 'X-CSRF-TOKEN': token.content,
    },
    baseURL: API_DOMAIN,
    withCredentials: true, // attaches cookie to request!!!
    withXSRFToken: true,
    // cache: cacheOptions,
    // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
    // This will set an `Authorization` header, overwriting any existing
    // `Authorization` custom headers you have set using `headers`.
    // Please note that only HTTP Basic auth is configurable through this parameter.
    // For Bearer tokens and such, use `Authorization` custom headers instead
    // auth: {
    //     username: 'janedoe',
    //     password: 's00pers3cret'
    // },
    // responseType: 'json', // default
    xsrfCookieName: 'XSRF-TOKEN', // default
    // allows handling of progress events for uploads browser only
    // onUploadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
    // },
    // onDownloadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
    // },
    // `socketPath` defines a UNIX Socket to be used in node.js.
    // e.g. '/var/run/docker.sock' to send requests to the docker daemon.
    // Only either `socketPath` or `proxy` can be specified.
    // If both are specified, `socketPath` is used.
    // socketPath: null, // default
    // httpAgent: new http.Agent({ keepAlive: true }),
    // httpsAgent: new https.Agent({ keepAlive: true }),
    // `cancelToken` specifies a cancel token that can be used to cancel the request
    // (see Cancellation section below for details)
    // cancelToken: new CancelToken(function (cancel) {
    // }),
    // proxy: {
    //     protocol: 'https',
    //     host: '127.0.0.1',
    //     port: 9000,
    //     auth: {
    //         username: 'mikeymike',
    //         password: 'rapunz3l'
    //     }
    // },
    cache: false,
    cache_expire_timeout: 1000 * 60 * 50, //ms 50min
    cache_force_update: false,
    cacher: LocalStorageCache,
    adapter:  function(config) {

        if(config.cache) {

            // const controller = new AbortController();
            // config.signal = controller.signal;
            // controller.abort();

            if(!config.cached_data ) {

                let promise = (async() => {
                    try {
                        return await getAdapter('xhr')(config);
                    } catch (reason) {
                        // alert('here 3');
                        config.cache.delete(config.cache_key);
                        throw reason;
                    }
                })();

                return promise; // Returns the saved response object
            } else {

                return new Promise(function (resolve, reject){

                    if(process.env.MIX_ENV != 'prod'){
                        console.log('axios returned CACHED RESPONSE', config.cached_data);
                    }
                    resolve({
                        data: config.cached_data,
                        status: 200,
                        statusText: "OK",
                        headers: config.headers,
                        config: config,
                    });

                });
            }

        }

        return getAdapter('xhr')(config);

    }
};


const apiAxios = axios.create(apiOptions);

apiAxios.interceptors.request.use(async config => {
// console.log('request interceptors',config);
    config.headers['Accept-Language'] = localStorage.getItem('app_locale') || defaultLang;
    // alert(config.url);
    if (config.cache) {

        const cache = LocalStorageCache;
        config.request_url = config.url;

        config.cache_key = generateReqKey(config);

        let cachedData = false;
        if(config.cache_expire_timeout > 0 && !config.cache_force_update){
            cachedData = cache.get(config.cache_key, config.cache_expire_timeout, config.cache_force_update);
        }
        if(config.cache_force_update){
            // console.log('Cache updated force');
        }
        if (cachedData ) {
            config.cached_data = cachedData;
        }
    } else {

    }
    return config;

}, error => {
    alert('errror');
    return Promise.reject(error);
} );

// Add a response interceptor
apiAxios.interceptors.response.use(response => {

// console.log('response.config.',response.config);
        if ( response.config.cache && response.config.cache_key) {
// alert('resp 1');
            // if(response.config.cached_data) {// If the cache is not hit/invalid or forced to update, the data is re-requested
            //     alert('resp 2');
            //     console.log('returning cached data in response', response.config.cached_data);
            //     return Promise.resolve({data:response.config.cached_data});
            // } else {
            //     alert('resp 3');
                const cache = LocalStorageCache;

                cache.set(response.config.cache_key, response.data, response.config.cache_expire_timeout);
            // }
        }
        return response;

    },
    error => {

        if (axios.isCancel(error)) return Promise.resolve(error.message.data)

        return Promise.reject(error)
    }
);


let initOptions = Object.assign({},apiOptions/*, options*/ );


function processSuccessResponse(vm, response, successCallback, showToast){

    let messageType = false;
    let text = ''
    const messageTypes = ['sweet_error', 'sweet_success','error', 'success', 'message', 'info','warning'];
    let disableToast = showToast === false;

    for(let i =0; i<messageTypes.length; i++) {
        messageType = messageTypes[i];

        if(response[messageType] || (response.data && response.data[messageType])) {
            text = (response[messageType] ? response[messageType] : response.data[messageType]);
            if(messageType == 'message') messageType = 'success'
            break;
        }
    }

    if (typeof successCallback === 'function') {
        successCallback.apply(vm, [response, {type:messageType, text: text}]);
    }

    let redirect = ((response.data && response.data.redirect)?response.data.redirect:response.redirect);
    if (redirect) {
        vm.redirect(redirect).then(()=>{
            if(!disableToast && messageType && text){
                if(messageType.includes('sweet_') ){
                    showSweetMessage(text,messageType.replace('sweet_', ''));
                } else {
                    vm.showToast(messageType, String(text));
                }

            }
        });
    } else {
        if(!disableToast && messageType && text){
            if(messageType.includes('sweet_') ){
                showSweetMessage(text,messageType.replace('sweet_', ''));
            } else {
                vm.showToast(messageType, String(text));
            }
        }
    }

}

function processErrorResponse(vm,error, errorCallback) {
    if (error) {

        if (typeof errorCallback === 'function') {
            errorCallback.apply(vm, [error]);
        }

        if (!error.response) {
            if (process.env.MIX_ENV != 'prod') {
                if (typeof error == 'string' || typeof error == 'number') {
                    vm.showToast('error', 'Server error: '+String(error));
                } else {
                    console.error('response error: ', error);
                }
            }

        } else {

            if (error.response.status === 422) {

                let errorData = error.response.data.errors;
                if(errorData){
                    vm.setServerSideErrorsByFieldsVid(errorData);
                }

                let message = '';
                if (errorData) {

                    for (let key in errorData) {
                        message += errorData[key] + '\n';
                    }

                } else {
                    message = error.response.data.error;
                }
                if(message)
                    vm.showToast('error', message);

            } else if(error.response.status === 419 ){ //CSRF token mismatch
                alert('token expired');
                vm.$user.clearUserData();
                vm.reload();
            }else if(error.response.status === 401 ){ //Unauthorized
                alert('Unauthorized');
                vm.$user.clearUserData();
                vm.reload();
            } else if (error.response.data.error) {
                if (process.env.MIX_ENV != 'prod') {
                    vm.showToast('error', 'Server error: '+error.response.data.error);
                }
            }else if (error.response.data.exception) {
                if (process.env.MIX_ENV != 'prod') {
                    vm.showToast('error', 'Server error: '+error.response.data.message);
                }
            }
        }

    }
}

const $http = {
    get: (url, data = {}, opt, fullUrl = false) => {
        // console.log('axios get locale', localStorage.getItem('app_locale'));

        if(data instanceof FormData) {
            data.append('_method', 'get'); //!!important to fix cookie size request error
        } else {
            data._method = 'get';
        }

        let axiosOpt = {
            // headers: {
            //     // "Accept-Language": localStorage.getItem('app_locale') || 'en'
            // },
            ...opt,
            ...{
                method: 'post',
                url: fullUrl? url : initOptions.baseURL + url,
                params: data
            }
        };
        // alert(localStorage.getItem('app_locale'));
        // axiosOpt.headers['Accept-Language'] = localStorage.getItem('app_locale') || 'en';
        // console.log('axiosOpt',axiosOpt);
        return apiAxios(axiosOpt)
    },
    post: (url, data = {}, opt, fullUrl) => {
        let axiosOpt = {
            headers: {
                // "Accept-Language": localStorage.getItem('app_locale') || 'en'
            },
            ...opt,
            ...{
                method: 'post',
                url: fullUrl? url : initOptions.baseURL + url,
                data: data
            }
        };
        // axiosOpt.headers['Accept-Language'] = localStorage.getItem('app_locale') || 'en';
        return apiAxios(axiosOpt)
    },
    delete: (url, data = {}, opt, fullUrl) => {

        if(data instanceof FormData) {
            data.append('_method', 'delete');
        } else {
            data._method = 'delete';
        }
        let axiosOpt = {
            headers: {
                // "Accept-Language": localStorage.getItem('app_locale') || 'en'
            },
            ...opt,
            ...{
                method: 'post',
                url: fullUrl? url : initOptions.baseURL + url,
                data: data
            }
        };
        // axiosOpt.headers['Accept-Language'] = localStorage.getItem('app_locale') || 'en';
        return apiAxios(axiosOpt)
    },
    put: (url, data = {}, opt, fullUrl) => {
        // const formData = new FormData()
        if(data instanceof FormData) {
            data.append('_method', 'put');
        } else {
            data._method = 'put';
        }

        let axiosOpt = {
            headers: {
                // "Accept-Language": localStorage.getItem('app_locale') || 'en'
            },
            ...opt,
            ...{
                method: 'post',
                url: fullUrl? url : initOptions.baseURL + url,
                data: data
            }
        };
        // axiosOpt.headers['Accept-Language'] = localStorage.getItem('app_locale') || 'en';
        return apiAxios(axiosOpt)
    },

};
//with vm
$http.async = function (vm, method, url, data, successCallback, errorCallback, options) {

    if (options && options.showProgress) {
        delete options.showProgress;
        options.onUploadProgress = progressEvent => {
            vm.percentsHttpRequestDone = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            if (vm.percentsHttpRequestDone == 100) {
                vm.percentsHttpRequestDone = false;
            }

        }
    }

    return $http[method]( url, data, options, false).then(function (response) {

        processSuccessResponse(vm,response, successCallback, (options ? options.show_toast : true));

    }.bind(vm)).catch(function (error) {

        processErrorResponse(vm,error, errorCallback);

    }.bind(vm));

};

//no vm asyncBeforeCreate
$http.publicAsync = function ( method, url, data, successCallback, errorCallback, options) {

    return $http[method]( url, data, options, true).then(function(response){

        if (typeof successCallback === 'function') {
            successCallback(response);
        }

    }).catch(function (error) {
        if (typeof errorCallback === 'function') {
            errorCallback(error);
        }
    });

};
$http.asyncDownload = function ( method, url, downloadedBaseFileName, data = {}, successCallback, errorCallback, options) {

    return $http[method]( url, data, {
        responseType: 'blob'
    }, false).then(function(resp){

        const href = URL.createObjectURL(resp.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', downloadedBaseFileName);
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        // if (typeof successCallback === 'function') {
        //     successCallback(response);
        // }

    }).catch(function (error) {

        if(error.status == '403'){
            showSweetMessage('Forbidden','error');
        } else if(error.status == '404'){
            showSweetMessage('Not found','error');
        }
        // if (typeof errorCallback === 'function') {
        //     errorCallback(error);
        // }
    });

};

//no vm full external url
$http.externalAsync = function ( method, url, data, successCallback, errorCallback, options) {

  return  axios[method](url).then(function(response){
      if (typeof successCallback === 'function') {
          successCallback(response);
      }

  }).catch(function (error) {
      if (typeof errorCallback === 'function') {
          errorCallback(error);
      }
  });

};


//initialize vue plugin
const httpPlugin = {

    install: (Vue, options) => {

        Vue.prototype.$API_DOMAIN = API_DOMAIN;

        Vue.prototype.$http = window.$http = $http;
        window.axios = apiAxios; //todo replace clean axios

    }
}

export default  httpPlugin;


