export default {

  path: '/',
  component: () => import('@views/layouts/guestLayout'),
  meta: {
    breadcrumb: ''
  },
  // redirect: to => {
  //     console.log('redirect');
  //     return {path: '/login', query: {}}
  // },
  children: [

    {
      path: '',
      component: () => import('@views/guest/Home'),
      name: 'guest.home',
      meta: {
        breadcrumb: 'app.home',
        title: 'app.home',
      }
    },
    {
      path: 'products/:category_slug',
      component: () => import('@views/guest/products/list'),
      props: true,
      name: 'guest.category_products',
      meta: {
        breadcrumb: 'app.new_products',
        title: 'app.new_products',
      }
    },
    {
      path: 'product/:product_slug',
      component: () => import('@views/guest/products/productPreview'),
      props: true,
      name: 'guest.product',
      meta: {
        breadcrumb: 'app.new_products',
        title: 'app.new_products',
        page_content_class: 'product_page'
      }
    },
    {
      path: 'doctors',
      component: () => import('@views/guest/users/doctors.vue'),
      // props: true,
      name: 'guest.doctors',
      meta: {
        breadcrumb: 'app.doctors',
        title: 'app.doctors',
      }
    },
    {
      path: 'doctors/:id',
      component: () => import('@views/guest/users/doctorPreview.vue'),
      // props: true,
      name: 'guest.doctor',
      meta: {
        breadcrumb: 'app.doctor',
        title: 'app.doctor',
        page_content_class: 'product_page'
      }
    },
    {
      path: 'categories',
      component: () => import('@views/guest/SaleCategories'),
      // props: true,
      name: 'guest.categories',
      meta: {
        breadcrumb: 'app.categories',
        title: 'app.categories',
      }
    },
    {
      path: 'faq',
      component: () => import('@views/guest/PageFaq'),
      props: true,
      name: 'guest.faq',
      meta: {
        breadcrumb: 'app.faq',
        title: 'app.faq',
      }
    },
    {
      path: 'help-center',
      components: {
        default: () => import('@views/guest/faqs/HelpCenter.vue'),
        LeftSidebar: () => import('@views/guest/includes/LeftSideBar.vue'),
      },
      props: true,
      name: 'help-center.index',
      meta: {
        breadcrumb: 'app.faq',
        title: 'app.faq',
      }
    },
    {
      path: 'help-center/:id',
      components: {
        default: () => import('@views/guest/faqs/HelpCenterTopic.vue'),
        LeftSidebar: () => import('@views/guest/includes/LeftSideBar.vue'),
      },
      props: true,
      name: 'help-center.topic',
      meta: {
        breadcrumb: 'app.help-center',
        title: 'app.help-center',
      },
    },
    {
      path: 'help-center/:id/:questionid',
      components: {
        default: () => import('@views/guest/faqs/HelpCenterQuestion.vue'),
        LeftSidebar: () => import('@views/guest/includes/LeftSideBar.vue'),
      },
      props: true,
      name: 'help-center.question',
      meta: {
        breadcrumb: 'app.help-center',
        title: 'app.help-center',
      }
    },
    {
      path: 'how-it-works',
      component: () => import('@views/guest/PageHowWorks'),
      props: true,
      name: 'guest.how_it_works',
      meta: {
        breadcrumb: 'app.how_it_works',
        title: 'app.how_it_works',
      }
    },
    {
      path: 'shopping-cart',
      component: () => import('@views/guest/ShoppingCart'),
      props: true,
      name: 'guest.shopping_cart',
      meta: {
        breadcrumb: 'app.shopping_cart',
        title: 'app.shopping_cart',
      }
    },
    {
      path: '403',
      component: () => import('@views/includes/errors/403'),
      name: 'errors.403',
      meta: {
        breadcrumb: 'app.403',
        title: 'app.403',
      }
    },
    {
      path: '404',
      component: () => import('@views/includes/errors/404'),
      name: 'guest.errors.404',
      meta: {
        breadcrumb: 'app.404',
        title: 'app.404',
      }
    },
    {
      path: '500',
      component: () => import('@views/includes/errors/500'),
      name: 'errors.500',
      meta: {
        breadcrumb: 'app.500',
        title: 'app.500',
      }
    },
    {
      path: 'login',
      component: () => import('@views/auth/loginForm'),
      name: 'guest.login',
      // alias: 'guest.login'
      meta: {
        breadcrumb: 'app.login',
        title: 'app.login',
        middleware: 'not_for_logged_in',
      }
    },
    {
      path: 'register',
      component: () => import('@views/auth/registrationForm'),
      name: 'guest.register',
      meta: {
        breadcrumb: 'app.register',
        title: 'app.register',
        middleware: 'not_for_logged_in',
      }
    },
    {
      path: 'reset-password-request',
      component: () => import('@views/auth/resetPasswordRequestForm'),
      name: 'guest.password_resetting',
      meta: {
        breadcrumb: 'app.password_resetting',
        title: 'app.password_resetting',
        middleware: 'not_for_logged_in',
      }
    },
    {
      path: '/password/reset',
      component: () => import('@views/auth/resetPasswordForm'),
      name: 'guest.password.reset',
      props: route => ({ token: route.query.token, email: route.query.email }),
      meta: {
        breadcrumb: 'app.password_reset',
        middleware: 'not_for_logged_in',
        title: 'app.password_reset',
      },

    },
    {
      path: '/auth/social',
      component: () => import('@views/auth/authSocial'),
      name: 'guest.auth_social',
      props: route => ({ token: route.query.token }),
      meta: {
        breadcrumb: '',
        title: 'app.auth_social',
        middleware: 'not_for_logged_in'
      }
    },
    {
      path: 'page/:page_slug',
      component: () => import('@views/guest/pages/show'),
      props: true,
      name: 'guest.page',
      meta: {
        breadcrumb: 'app.page',
        title: 'app.page',
        page_content_class: 'content-container container'
      }
    },

    {
      path: 'blog',
      component:  () => import('@views/guest/blog/list'),
      name: 'guest.blog',
      meta: {
        breadcrumb: 'app.blog',
        title: 'app.blog',
      }
    },

    {
      path: 'blog/article/:article_id',
      component:  () => import('@views/guest/blog/list'),
      name: 'guest.blog_article',
      props: true,
      meta: {
        breadcrumb: 'app.article',
        title: 'app.article',
      }
    },


    // {
    //   path: '/site-policy',
    //   component: () => import('@views/guest/sitePolicy'),
    //   name: 'guest.site_policy',
    //   props: route => ({ token: route.query.token, email: route.query.email }),
    //   meta: {
    //     breadcrumb: 'app.site_policy',
    //     title: 'app.site_policy',
    //   },
    //
    // },
    // {
    //   path: '/site-terms',
    //   component: () => import('@views/guest/siteTerms'),
    //   name: 'guest.site_terms',
    //   props: route => ({ token: route.query.token, email: route.query.email }),
    //   meta: {
    //     breadcrumb: 'app.site_terms',
    //     title: 'app.site_terms',
    //   },
    //
    // },
    // {
    //   path: '/acceptation/:id',
    //   component: () => import('@views/guest/acceptation.vue'),
    //   name: 'guest.acceptations.view',
    //   props: true,
    //   meta: {
    //     breadcrumb: 'app.acceptation',
    //     title: 'app.acceptation',
    //   },
    //
    // },

    {
      path: 'no-connection', //offline page
      component: () => import('@views/guest/noConnection.vue'),
      name: 'guest.no_connection',
      meta: {
        breadcrumb: 'app.no_connection',
        title: 'app.no_connection',
      },

    },
  ]
}