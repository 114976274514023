import Vue from 'vue'
import Vuex from "vuex"

Vue.use(Vuex)

import app from './modules/app'
import shopCart from './modules/shop-cart'
import echoChannels from './modules/echo-channels'
import alerts from "./modules/alerts";
import verticalMenu from "./modules/vertical-menu";

export default new Vuex.Store({
    modules:{
        echoChannels,
        alerts,
        app,
        verticalMenu,
        shopCart
    },

    strict: process.env.NODE_ENV !== 'prod' //throwing errors
})
