// import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {

    shopping_cart_items: [],
    doctor_reserving_data:[]
  },

  getters: {

    // needShowShopCart(state){
    //   // alert('her');
    //   // state.needShowShopCart,
    //   console.log('needShowBasket____',state.selectedChairs,state.selectedChairs.length > 0,state.forceClose);
    //   // return localStorage.getItem('basket_score');
    //   // const regex = new RegExp('/basket$');
    //   // const globalRegex = new RegExp('foo*', 'g');
    //   // let isBaskePage = false;
    //   // alert(window.location);
    //   // if(/basket$/.test(window.location)){
    //   //   isBaskePage = true;
    //   // }
    //
    //   return !state.forceClose && state.selectedChairs.length > 0;
    //  }
  },
  mutations: {

    setShopCartItems(state, data){
      // alert(data.length);
      state.shopping_cart_items = data.shopping_cart_items;
    },
    updateShopItemQuantuty(state, data){
      state.shopping_cart_items.findFirstWhere('id',data.item_id)['selected_quantity'] = data.quantity;
    },
    updateDoctorReservingData(state, data){
      state.doctor_reserving_data = data;
    }

  },
  actions: {

    GET_SHOPPING_CART({dispatch, state, commit, rootState}, data) {

      return new Promise((resolve, reject) => {
        if(!data){
          data = {};
        }

        return $http.publicAsync('get', 'api/shopping-cart',data, function(response){
          commit('setShopCartItems', response.data);
        }, function(error){
          reject(error);
        });
            // .get(, {withCredentials: true, params: (data? data: {})})
            // .then(function (response) {
            //
            //   commit('setShopCartItems', response.data);
            //
            //   return resolve(response);
            // })
            // .catch(error => reject(error));
      });

    },


    RESERVE_DOCTOR_TIME({dispatch, state, commit, rootState}, {data, vm}) {

      return new Promise((resolve, reject) => {
        // if(!data){
        //   data = {};
        // }
// console.log('vm', vm);
        return $http.async(vm,'post', `api/patient/doctors/${data.doctor_id}/reserve-time`, data, function(resp){
          // commit('setShopCartItems', response.data);
          resolve(resp);
        }, function(error){
          reject(error);
        });
        // .get(, {withCredentials: true, params: (data? data: {})})
        // .then(function (response) {
        //
        //   commit('setShopCartItems', response.data);
        //
        //   return resolve(response);
        // })
        // .catch(error => reject(error));
      });

    },


  },
}
