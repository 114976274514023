import Vue from "vue";

Vue.config.productionTip = false;

window.Vue = require('vue');
// window._ = require('lodash');
Vue.prototype.$moment = window.moment = require('moment');

import App from '@views/App';

// import VueCompositionAPI from '@vue/composition-api'
// Vue.use(VueCompositionAPI)
// import Cookies from 'js-cookie';

import '@plugins/service-worker'
import '@plugins/toastification'
import '@plugins/portal-vue'
import '@plugins/sweet-alerts'
import '@plugins/vue-select'
import '@plugins/breadcrumbs'
import '@plugins/progresbar'
import '@plugins/vee-validator/validator'
import '@plugins/bootstrap-vue'
import '@plugins/element-ui'
import '@plugins/axios'
// import '@plugins/echo'
import '@plugins/feather-icon'
import '@plugins/ripple'
import '@plugins/custom/preloader'
import '@plugins/custom/user'
import '@plugins/custom/sockets'
// import '@plugins/mavon-editor'

import './helpers/utils';
import './helpers/mixins';

import i18n from '@plugins/i18n'
import store from './store'
import router from '@plugins/router';

const app = new Vue({
    i18n,
    render: h => h(App),
    store,
    router,
    data(){
        return {
            // events: new Vue(),
            requestProgress: false,
            reloadIndex: 'init_index',
        }
    },

    computed:{
        APP_INTERFACE(){
            return this.$store.state['app'].APP_INTERFACE;
        }
    },

    beforeCreate() {

        this.$store.dispatch('app/GET_SITE_SETTINGS', {}).then((resp)=>{

        });

        this.$store.dispatch('shopCart/GET_SHOPPING_CART', {}).then((resp)=>{

        });

    },

    created () {

        let self = this;

        this.$Progress.start();

        if(this.$user.auth){

            this.$sockets.initUserSocket(this.$user.id); //for auth user
            this.$sockets.getIo().on('new_user_notification', function(){
                self.$store.commit('alerts/setNotifications', self.$store.state['alerts'].total_notification_count + 1);
            });

            this.$store.dispatch('alerts/GET_NOTIFICATIONS', {}).then((resp)=>{

            });

        }
    },

    mounted () {
        // this.$Progress.finish();
        this.eventBus.$on(`layoutLoaded`, function(){
            document.getElementById('loading-bg').style.display = 'none';
        });
    },


}).$mount('#app');

window.$_app = app;



