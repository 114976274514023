import Vue from 'vue'
import {
  ValidationProvider, ValidationObserver,/* configure, localize,*/ extend
} from 'vee-validate'

// import en from './i18n/en.json';
// import pl from './i18n/pl.json'
// import ru from './i18n/ru.json'
// import es from './i18n/es.json'
// import store from "@resources/js/store";
// configure({
//   generateMessage: localize({
//     en,
//     pl,
//     ru,
//     es
//   })
// });
//
// localize('ru');
// configure({
//   generateMessage: localize({
//     [store.state['app'].appLocale] : store.state['app'].translations['validation_front']
//     // en,
//     // pl,
//     // ru,
//     // es
//   })
// });

// localize(store.state['app'].appLocale);
// console.log('valid store:', store.state['app'].appLocale,store.state['app'].translations['validation_front']);

// register globalRules !!!
import {
  required,
} from 'vee-validate/dist/rules'
import {
  numeric,
  isCheckboxChecked,
} from './rules/validations'
import {
  email,
} from 'vee-validate/dist/rules'


extend('required', required);
extend('email', email);
extend('isCheckboxChecked', isCheckboxChecked)
// extend('numeric', numeric);


Vue.component([ValidationProvider, ValidationObserver])
