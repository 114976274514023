import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

let locale = document.documentElement.lang.substr(0, 2);
let messages  = require('./locales/en.json');
let i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: {[locale]:messages},
});

export function t(alias){
  return i18n.t(alias);
}

export default i18n
