import {t} from '@resources/js/plugins/i18n'
import Vue from 'vue'

export function showSweetMessage(text, icon){
    Vue.swal({
        title: '',
        text: text,
        icon: icon,
        showCancelButton: false,
        showClass: {
            popup: 'animate__animated animate__flipInX',
        },
        confirmButtonText: t('buttons.ok'),
        customClass: {
            confirmButton: 'btn btn-' + (icon == 'danger'? 'magenta' : icon),
        },
    })
}
