import MD5 from "@helpers/utils/partial/md5";
// import {LocalStorageCache} from "@plugins/axios/cache-adapters/localStorage";

export function generateReqKey(config) {
    const { method, request_url, params, data } = config;
    // console.log('keyGen', method, request_url, params,data);
    return MD5([method, request_url, JSON.stringify(params), JSON.stringify(data) ].join(""));
}
function getCurrentTime() {
    return new Date().getTime()
}
// export function isCachable(cache) {
//     // console.log('cachable', cache);
//     return (cache.set && cache.get && cache.delete && cache.clear &&typeof cache.get === 'function' && typeof cache.set === 'function'
//         && typeof cache.delete === 'function' && typeof cache.clear === 'function'
//     );
// }

// export function cacheAdapterEnhancer(adapter, options) {
//
//     // const { /*maxAge*//* enabledByDefault = false*/
//     //     /*cacheFlag = "cache",*/ default_cacher,
//     // } = options;
// // console.log('LocalStorageCache',LocalStorageCache);
//     return (config) => {
//
//         const { /*url,*/ method /*,params, forceUpdate*/ } = config;
//
//         // let useCache = config[cacheFlag];
//
//         if (method === "get" && config.cache) {
// // console.log('adapte_config', config);
//             // const cache = config.cacher;
//             // console.log('cache class',cache);
//             // let requestKey = generateReqKey(config);  // Generate the request Key
//             // console.log('reqKey',requestKey);
//             // let cachedData = cache.get(requestKey); // Retrieve the response object corresponding to the request key from the cache
//             // console.log('data from storage',responsePromise);
//             if(!config.cached_data /*|| forceUpdate*/) {// If the cache is not hit/invalid or forced to update, the data is re-requested
//                 let promise = (async() => {
//                     try {
//                         return await adapter(config);  // Use the default xhrAdapter to send requests
//                     } catch (reason) {
//                         config.cache.delete(config.cache_key);
//                         throw reason;
//                     }
//                 })();
//                 // cache.set(requestKey, responsePromise, maxAge);  // Save the response object returned by the request
//                 return promise; // Returns the saved response object
//             } else {
//                 return new Promise(function (resolve, reject){
//                     var response = {
//                         data: {},
//                         status: 200,
//                         statusText: "OK",
//                         // headers: responseHeaders,
//                         config: config,
//                         // request: config
//                     };
//                     settle(resolve, reject, response);
//                 });
//             }
//             // return responsePromise;
//         }
//         return adapter(config); // Use the default xhrAdapter to send requests
//     };
// }



// export function settle(resolve, reject, response) {
//     var validateStatus = response.config.validateStatus;
//     // Note: status is not exposed by XDomainRequest
//     if (!response.status || !validateStatus || validateStatus(response.status)) {
//         resolve(response);
//     } else {
//         reject(createError(
//             'Request failed with status code ' + response.status,
//             response.config,
//             null,
//             response.request,
//             response
//         ));
//     }
// };
// Get the current time
