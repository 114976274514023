// import PreloaderComponent from '@views/includes/preloader';
import {io} from 'socket.io-client'
// const token = document.cookie.split('; ')
//     .find(row => row.startsWith('XSRF-TOKEN='))
//     .split('=')[1];

let domain =  process.env.MIX_BASE_API_URL.slice(0, -1);

let port =  process.env.MIX_SOCKET_PORT;

export default {
    install(Vue, options) {
        // Vue.component('preloader', PreloaderComponent)
        // Vue.myAddedMethod = function() {
        //     return Vue.myAddedProperty
        // }
        // Vue.prototype.$myAddedProperty = 'Example Property'
        // Vue.prototype.$myAddedMethod = function() {
        //     return this.$myAddedProperty
        // }
        // console.log('preloader installing');
        let headers = {
            // 'Accept': 'application/json',
            'Accept-Language':  'pl',
            // 'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
            // 'X-XSRF-TOKEN': unescape(token),
        };

        const sockets = {

            chatSocket: false,
            loggedUserIo: false,
            io: false,
            initChatSocket(chatId) {
                const privateSocket = io(domain+':'+port,{
                    // withCredentials: true,
                    // autoConnect: false,
                    // ca: [
                    //     fs.readFileSync("/path/to/client-cert.pem") // mkcert ca
                    // ]
                    extraHeaders: headers,
                    // path: "/chat-" + chatId
                    // path: "/chat-" + chatId +'/socket.io'
                });
              this.chatSocket = privateSocket;
            },

            initLoggedUserPrivateChannel(userId) {
                const privateSocket = io(domain+':'+port,{
                    // withCredentials: true,
                    extraHeaders: headers,
                    // path: "/socket.io/users-" + userId
                });
                this.loggedUserIo = privateSocket;
            },
            initUserSocket(user_id){
                this.getIo().emit('user_connected',user_id);
            },
            getIo(){
                if(!this.io){
                    this.io = io(domain+':'+port,{
                        // withCredentials: true,
                        extraHeaders: headers,
                        // path: "/socket.io/users-" + userId
                    });
                }
                return this.io;

            }
            // show(){
            //     // console.log('show',this.obj);
            //     if(this.obj)
            //     this.obj.style.display = 'block';
            // }
        };
        // Vue.$preloader = preloader
        Vue.prototype.$sockets = sockets;
        // Vue.prototype.created = function(){
        //     alert('dfdf');
        // }
        // console.log('VUUUE', Vue.prototype);
        // Vue.created = function(){
        //     alert('dfdf');
        // }
        // Vue.$mount(alert(123););
        // root.$on('create', options.data.onCreate)
        // Vue.mixin({
            // data: function () {
            //     return {
            //         $preloader: preloader
            //     };
            // },
            // created() {
            //     console.log('creeeeeated preloader');
            //     this.$preloader.obj = document.getElementById('site-preloader');
            // }
        // });
        // Vue.mixin({
        //     data: function () {
        //         return {
        //             $preloader: preloader
        //         };
        //     }
        // });

    }
}

