<template>
    <div id="site-preloader" ref="site--preloader" style="">
        <div id="status" style="">
            <div class="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // components: {
    //     LineChart, BarChart
    // },
    data() {
        return {
            // userChart: {
        }
    },
    mounted(){
        this.$preloader.obj = this.$refs['site--preloader'];
        // console.log('mm',this.$preloader.obj,this.$refs['site--preloader']);
        // this.$preloader.hide();
        this.$preloader.show();
    }
}
</script>
