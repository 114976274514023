export default{

    methods: {
        calcDiscount(prod){
          return parseFloat(Math.max(prod.max_categories_discount, parseFloat(prod.discount ? prod.discount : 0)));
        },
        calcDiscountPrice(prod) {
            let prodPrice = parseFloat(this.calcProductPrice(prod));
            // console.log(discount,prodPrice);
            return (prodPrice - (prodPrice * this.calcDiscount(prod)) / 100).toFixed(2);
        },

        calcProductPrice(prod) {

            return (parseFloat(prod.price) + parseFloat(prod.options_price ? prod.options_price : 0)).toFixed(2);
        },
        addToShoppingCart(item_type, item_id, option_ids, quantity, additionalProps) {

            this.confirmAction(this.$t('messages.confirmation'), this.$t('messages.confirmation_add_to_shop_cart'), 'info',
                function (result) {}, false,false,{
                    confirmButtonText: this.$t('app.add'),
                    cancelButtonText: this.$t("app.cancel"),
                    customClass: {
                        confirmButton: 'btn btn-info',
                        cancelButton: 'btn btn-outline-default ml-1',
                    },
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {

                        return new Promise((resolve, reject) => {

                            let data = {
                                item_id: item_id,
                                item_type: item_type,
                                option_ids: option_ids,
                                quantity: quantity,
                                use_default_options: additionalProps && additionalProps.use_default_options? 1 : 0
                            }
                            // if(additionalProps){
                            //     data = Object.assign(data,additionalProps);
                            // }
                            $http.async(
                                this,
                                'post', 'api/shopping-cart',
                                data,
                                function (resp) {
                                    // console.log('ss',resp);

                                    if(resp.data && resp.data.error){
                                        resolve(this.$swal.showValidationMessage(`Request failed. ` + resp.data.error));
                                    } else {

                                        this.$store.dispatch('shopCart/GET_SHOPPING_CART', {}).then((resp)=>{

                                        });
                                        resolve(
                                            this.confirmAction('', this.$t("messages.added_to_shopcart"), 'success', function (result) {
                                                // window.location.href = LA.base_url + '/shopcart';
                                                this.$router.push({ name: 'guest.shopping_cart' });
                                            }, false, false, {

                                                confirmButtonText: this.$t('app.open_shopcart'),
                                                cancelButtonText: this.$t("app.continue"),
                                                customClass: {
                                                    confirmButton: 'btn btn-success',
                                                    cancelButton: 'btn btn-outline-default ml-1',
                                                },

                                            })
                                        );

                                    }

                                }, function(){
                                    resolve(this.$swal.showValidationMessage(`Request failed`));
                                },{show_toast: false});
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });


        },
        initCounters(countdown){

            clearInterval(this.counterItvalId);

            this.$nextTick(function(){

                let counterObjs = document.getElementsByClassName('live-counter');

                this.counterItvalId = setInterval(function(){

                    for(let i = 0; i < counterObjs.length; i++ ){
                        let t = counterObjs[i];
                        let seconds = (countdown === false || t.hasAttribute('data-up-counter'))? (parseInt(t.getAttribute('data-seconds')) + 1) : (t.getAttribute('data-seconds') - 1);
                        t.setAttribute('data-seconds',seconds);
                        t.innerText = seconds.counterFormat();
                    }

                }, 1000);

                return this.counterItvalId;

            });

        },
    },


}