import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue';
import {BRow, BCol, BBreadcrumb, BBreadcrumbItem} from 'bootstrap-vue'

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-breadcrumb', BBreadcrumb);
Vue.component('b-breadcrumb-item', BBreadcrumbItem);


