import PreloaderComponent from '@views/includes/preloader';
export default {
    install(Vue, options) {
        Vue.component('preloader', PreloaderComponent)
        // Vue.myAddedMethod = function() {
        //     return Vue.myAddedProperty
        // }
        // Vue.prototype.$myAddedProperty = 'Example Property'
        // Vue.prototype.$myAddedMethod = function() {
        //     return this.$myAddedProperty
        // }
        // console.log('preloader installing');
        const preloader = {
            obj: false,
            hide() {
                // console.log('hide',this.obj);
                //todo add animation
                if(this.obj)
                this.obj.style.display = 'none';
            },
            show(){
                // console.log('show',this.obj);
                if(this.obj)
                this.obj.style.display = 'block';
            }
        };
        // Vue.$preloader = preloader
        Vue.prototype.$preloader = preloader;
        // Vue.prototype.created = function(){
        //     alert('dfdf');
        // }
        // console.log('VUUUE', Vue.prototype);
        // Vue.created = function(){
        //     alert('dfdf');
        // }
        // Vue.$mount(alert(123););
        // root.$on('create', options.data.onCreate)
        // Vue.mixin({
            // data: function () {
            //     return {
            //         $preloader: preloader
            //     };
            // },
            // created() {
            //     console.log('creeeeeated preloader');
            //     this.$preloader.obj = document.getElementById('site-preloader');
            // }
        // });
        // Vue.mixin({
        //     data: function () {
        //         return {
        //             $preloader: preloader
        //         };
        //     }
        // });

    }
}

