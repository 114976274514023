import Vue from 'vue'
import VueRouter from "vue-router";
import RouterRule from './routes';
import {t} from '@resources/js/plugins/i18n'
import store from '@resources/js/store'

Vue.use(VueRouter); // creates $router prop

const router = new VueRouter(RouterRule);

router.beforeEach((to, from, next) => {

    Vue.prototype.$Progress.start();
    let fromRoute = Vue.prototype.$user.CURRENT_ROUTER? Object.assign({}, Vue.prototype.$user.CURRENT_ROUTER) : false;
    Vue.prototype.$user.PREVIOUS_ROUTER = fromRoute;
    Vue.prototype.$user.CURRENT_ROUTER = to;

    let plainLink = router.resolve(to);
    if(plainLink && plainLink.href){
        plainLink = plainLink.href;
    }

    let APP_INTERFACE = window.getAppInterface(plainLink);

    Vue.prototype.$user.APP_INTERFACE = APP_INTERFACE;

    if(to.query.flashmessage == 1){

        store.dispatch('app/GET_FLASHMESSAGE', {});

        if(to.query.redirect){

            if(to.query.redirect == 'time-orders'){
                delete to.query.redirect;
                router.replace({name: 'patient.information', query:{tab:to.query.tab}});
                return;
            }

        } else {
            delete to.query.flashmessage;
            router.replace(to);
        }
    }

    let loggedUser = Vue.prototype.$user;

    if(loggedUser.auth && APP_INTERFACE !== 'GUEST' && /*in_array(APP_INTERFACE, ['DOCTOR', 'PATIENT']) &&*/ APP_INTERFACE.toLowerCase() !== loggedUser.account_owner_type.toLowerCase()){

        Vue.prototype.$user.APP_INTERFACE = APP_INTERFACE = loggedUser.account_owner_type.toUpperCase();
        router.push({name: APP_INTERFACE.toLowerCase()+'.errors.403'});
    }

    if(loggedUser.auth){

        if(to.meta.middleware == 'not_for_logged_in'){
            let dashboardRoute = {name: Vue.prototype.$user.account_owner_type + '.dashboard'};
            router.push(fromRoute ? fromRoute : dashboardRoute);
        }
        if(typeof to.meta.click_handler === 'function'){
            to.meta.click_handler(window.$_app, t);
            store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);
            return;
        }

    }

   if (APP_INTERFACE != 'GUEST') {

        Vue.prototype.$user.checkAuth().then(() => {

            if(APP_INTERFACE == 'ADMIN' && to.name != 'admin.errors.403' && to.meta?.permissions && !Vue.prototype.$user.canAll(to.meta.permissions)){
                router.push({name: 'admin.errors.403'});
            } else {
                next();
            }

        }).catch((e) => {

            console.log('router error',e);
            router.push({name: 'guest.login'});

        });

    } else {
            next();
    }


});

router.afterEach((to, from) => {
    // Vue.prototype.$Progress.finish();
    let title = to.meta.title?  t(to.meta.title) : '';
    document.title = title || localStorage.getItem('app_name');

    Vue.nextTick(() => {
        window.$_app .$Progress.finish();
        window.$_app.$preloader.hide();
        window.$_app.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);
    });
});

export default router
