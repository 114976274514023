<template>
  <div id="app" class=" main-wrapper" :id="setWrapperId()">
       <vue-progress-bar></vue-progress-bar><!--    page-loading bar-->

    <router-view :key="$root.reloadIndex" v-slot="{ Component }">
      <transition name="zoom-fade" mode="out-in" >
        <component  :is="Component" ></component>
      </transition>
    </router-view>
  </div>
</template>

<script>

import {$themeBreakpoints, $themeColors} from "@themeConfig";
import {useCssVar} from "@vueuse/core";

export default {
  components:{},
  data: function(){
    return {

      windowWidth: 0,
      pageTemplateClass:'',
      renderRootComponent: true
    }
  },
  watch:{
    '$route': {
      handler: function(currentRoute) {
        // console.log('route-meta',currentRoute);
        
        this.$store.commit('app/UPDATE_CURRENT_ROUTE', currentRoute);
      },
      deep: true,
      immediate: true
    },

    windowWidth(val){
      this.$store.commit('app/UPDATE_WINDOW_WIDTH', val);
    },

  },
  computed: {
    layout(){
      return this.$route.meta.layout || "guest-layout"
    }
  },
  methods:{
    resizeHandler(){
      this.windowWidth = window.innerWidth;
    },
    setWrapperId(){
      return this.$store.state.app.APP_INTERFACE;
    }
  },
  created(){
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.resizeHandler);
    // $http.async(this,'get','sanctum/csrf-cookie').then(response => {
    //   // console.log('sanctum resp',response);
    // });
  },
  unmounted(){
    window.removeEventListener('resize', this.resizeHandler)
  },

  beforeCreate() {

    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    this.$store.commit('app/SET_ACTIVE_APP_INTERFACE', window.getAppInterface());

  },
}
</script>