var render, staticRenderFns
import script from "./FeatherIcon.vue?vue&type=script&lang=js&"
export * from "./FeatherIcon.vue?vue&type=script&lang=js&"
import style0 from "./FeatherIcon.vue?vue&type=style&index=0&id=4c97e906&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\ospanel\\domains\\medservis-vue.loc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c97e906')) {
      api.createRecord('4c97e906', component.options)
    } else {
      api.reload('4c97e906', component.options)
    }
    
  }
}
component.options.__file = "resources/js/plugins/feather-icon/FeatherIcon.vue"
export default component.exports