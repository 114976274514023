window.addEventListener('load', () => {
    registerSW();
});

async function registerSW() {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register('/sw.js'); // scope( под какими урлами ему работать to specify under which url need process files, default all urls )
            console.log('Sw installed');
        } catch (e) {
            console.log(`SW registration failed`, e);
        }
    }else{
        console.log('service workers are not supported.');
    }
}
