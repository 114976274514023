// import axios from 'axios';
export default {

    install(Vue, options) {

        let blankUserData = {
            id: 0,
            name: '',
            // firm_name: '',
            // short_firm_name: '',
            first_name: '',
            last_name: '',
            account_owner_type:'',
            birth_date: '',
            country: '',
            region: '',
            province: '',
            apartment: '',
            city: '',
            street: '',
            house_number: '',
            post_code: '',
            gender: '',
            phone: '',
            email: '',
            login: '',
            is_subscription_owner: 0,
            locale:'',

            avatar: '',
            catalog: '',
            vk: '',
            fb: '',
            instagram: '',

            nr_pwz:'',
            experience_desc:'',
            schedule_interval:'',

            acceptations:{},

            permissions: [],
            doctor_types: [],
            roles: [],
        };

        let user = {

            auth: false,
            APP_INTERFACE:'',
            CURRENT_ROUTER:false,
            PREVIOUS_ROUTER:false,

            checkAuth() {

               return new Promise((resolve, reject) => {
                  return $http.publicAsync('get', 'api/auth/user', {},
                   function(response){

                       user.fillUser(response.data.user);

                       resolve(response);
                   }, function(error) {


                       if(error.response && error.response.status === 423 && in_array(error.response.data.redirect, ['change-password', 'verify-email', 'terms-and-conditions'])) {

                           if(error.response.data.user){

                               user.fillUser(error.response.data.user);

                                setTimeout(()=>{ //todo set interval to prevent closing modal by client
                                    if(error.response.data.redirect == 'change-password'){

                                        window.$_app.eventBus.$emit('openPasswordResetModal');

                                    } else if(in_array(error.response.data.redirect, ['verify-email','terms-and-conditions'])){

                                        window.$_app.$store.commit('app/setDisableSiteReason',error.response.data.redirect);
                                        window.$_app.eventBus.$emit('openDisabledSiteAccessModal');
                                    }

                                }, 2000);

                               resolve(error.response);

                           } else{

                               user.clearUserData();
                               user.resolveFailRedirect();
                           }

                           // window.$_app.$store.state['app'].showGlobalModal();
                       }else if(error.response && error.response.status === 401){

                           user.clearUserData();
                           user.resolveFailRedirect();
                       } else {
                           // console.log('heeere 2');
                           // alert('err'+this.APP_INTERFACE);
                           user.clearUserData();
                           user.resolveFailRedirect();
                       }

                       // window.$_app.$preloader.hide();
                       // return (this);
                       reject(error);
                   });
               });

            },
            clearUserData(){
                // localStorage.setItem('token', '');
                localStorage.setItem('user', '');

                for(let prop in user){
                    //
                    if(blankUserData.hasOwnProperty(prop)){
                        console.log(typeof user[prop]);
                        if(typeof user[prop] != 'function'){
                            user[prop] = '';
                            // console.log('this', this );
                            Vue.set(user, prop, '');
                        }

                    }
                }
                user.auth = false;
                Vue.set(user, 'auth', false);
                console.log('logout',user);
            },

            loginUser(data){

                return $http.async(window.$_app, 'post','login', data, (resp) => {
                    window.$_app.$preloader.show();

                    if(resp.data.user ) {

                        window.$_app.$sockets.initUserSocket(resp.data.user.id); //for auth user
                        window.$_app.$sockets.getIo().on('new_user_notification', function(){
                            window.$_app.$store.commit('alerts/setNotifications', window.$_app.$store.state['alerts'].total_notification_count + 1);
                        });


                        this.fillUser(resp.data.user);

                        if(user.locale != window.$_app.$store.state['app'].locale){
                            window.$_app.$store.dispatch('app/UPDATE_LOCALE', {locale:user.locale, set:true})
                        }
                        // localStorage.setItem('token', resp.data.token);

                        this.resolveLoginRedirect(resp.data.user.account_owner_type);

                    } else {

                        this.clearUserData();
                        this.resolveFailRedirect();
                    }

                });

            },

            loginSocial(token){

                localStorage.setItem('token',token);
                this.auth = true;
                window.$_app.$router.push({name: 'lk.dashboard'});

            },

            logout(data){
                return $http.async(window.$_app,'post','logout', {}, (resp) => {

                    window.$_app.$preloader.show();

                    this.clearUserData();
                    // localStorage.setItem('logoutEnv',window.$_app.$root.APP_INTERFACE);
                    window.$_app.$router.push('/login').then(function(){
                        window.$_app.$preloader.hide();
                    });

                });

            },

            register(regData) {

                return $http.async(window.$_app,'post','register', regData, (resp) => {
                    window.$_app.$preloader.show();
                    if(resp.data.user /*&& resp.data.token*/) {

                        this.fillUser(resp.data.user);
                        localStorage.setItem('token', resp.data.token);

                        this.resolveLoginRedirect(resp.data.user.permissions);

                    } else {
                        this.clearUserData();
                        this.resolveFailRedirect();
                    }

                });

            },

            resolveLoginRedirect(account_owner_type){
                window.$_app.$router.push({name: this.account_owner_type+'.dashboard'});
            },

            resolveFailRedirect() {
                // alert('err'+this.APP_INTERFACE);
                if (!user.auth) {
                        // window.$_app.$router.push({name: this.APP_INTERFACE.toLowerCase()+'.login'});
                        window.$_app.$router.push({name: 'guest.login'});
                }
            },

            fillUser(data) {

                let loggedUser = Object.assign({}, blankUserData);
                loggedUser.password = null;
                for (let prop in data) {
                    if (loggedUser.hasOwnProperty(prop)) {
                        loggedUser[prop] = data[prop];
                        user[prop] = data[prop];
                        Vue.set(user, prop, data[prop]);
                    }
                }

                user.auth = true;
                Vue.set(user, 'auth', true);

                if (process.env.MIX_ENV == 'dev') {
                    localStorage.setItem('user', JSON.stringify(loggedUser));
                } else {
                    localStorage.setItem('user', btoa(JSON.stringify(loggedUser)));
                }

                localStorage.setItem('locale', loggedUser.locale);
            },

            initFromStorage(){
                let u = localStorage.getItem('user');
                if(u){

                    if (process.env.MIX_ENV == 'dev') {
                        u = JSON.parse(u);
                    } else {
                        u = atob(JSON.parse(u));
                    }

                    this.fillUser(u);
                }
            },

            can(permission) {

                if(!user || !user.auth) {
                    return false;
                }
                let slugs = permission.split(':');
                let module = slugs[0];

                return user.permissions['site']?.includes('site:full_access') ||  user.permissions[module] && user.permissions[module].includes(permission);
            },

            canAll(permissions) {
                let isAllowed = true;
                    for(let i = 0; i < permissions.length; i++) {
                        if(!isAllowed) break;
                        isAllowed = user.can(permissions[i]);
                    }
                return isAllowed;
            },

            canSome(permissions) {

                for(let i = 0; i < permissions.length; i++) {
                    if(user.can(permissions[i])) return true
                }

            },

            // hasRole(role) {
            //     return false;
            //     // return user && user.roles.includes(role);
            // },
            getPreparedName(u){
                if(!u){
                //     u = user;
                    return  '--';
                }
                return  u.first_name + ' ' + u.last_name;
            }
        };

        user.initFromStorage();


        Vue.prototype.$user = user;

        // Vue.computed({
        //
        // });

        Vue.mixin({
            data: function () {
                return {
                    $user: user
                };
            },
        });


    }
}

