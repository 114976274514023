// import api from '../../components/plugin/api'
const state =  {
        channelsList: [],
        // eventSettings: null,
    };

const mutations = {
    setEventList(state, payload) {
        // if(state.eventList.tournament_id)state.eventList.tournament_id = parseInt(state.eventList.tournament_id)
        state.channelsList = payload
    },
    addNewListeningChannel(state, channelName){
       let alreadyAddedChanel = state.channelsList.findFirstWhere('name',channelName);
       if(!alreadyAddedChanel) {
           state.channelsList.push({name:channelName});
       }
        console.log('state.channelsList',state.channelsList);
    }
    // setEventSettings(state, payload) {
    //     state.eventSettings = payload
    // },
    // setEventClubs(state, payload) {
    //     state.eventSettings[payload.name] = payload.value
    // }
};

const getters = {
    channelsList(state) {
        return state.channelsList;
    },
    getChannel(state){

        // var self = this;
        return function (channelName) {
            return state.channelsList.findFirstWhere('name',channelName);
        };
    }

};

const actions = {

    // getEventList: ({ commit }, payload) => new Promise(resolve => {
    //     $http.async(window.$_app,'get', 'api/events', payload, (resp => {
    //         commit('setEventList', resp.data.data);
    //         resolve(resp);
    //     }), error => {
    //         resolve(error);
    //     });
    // }),
    // getEventById: ({ commit }, id) => new Promise(resolve => {
    //     $http.async(window.$_app,'get', `api/events/${id}`, {}, (resp => {
    //         resolve(resp.data.data);
    //     }), error => {
    //         resolve(error);
    //     });
    // }),

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
