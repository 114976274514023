const state =  {
    notifications: [],
total_notification_count: 0
};

const mutations = {
    setNotifications(state, payload) {
        // if(state.eventList.tournament_id)state.eventList.tournament_id = parseInt(state.eventList.tournament_id)
        state.total_notification_count = payload;
    },

};

const getters = {
    // channelsList(state) {
    //     return state.channelsList;
    // },


};

const actions = {

   GET_NOTIFICATIONS({dispatch, state, commit, rootState}, data) {

        return new Promise((resolve, reject) => {
            axios
                .get(process.env.MIX_BASE_API_URL+'api/notifications', {withCredentials: true, params: (data? data: {})})
                .then(function (response) {

                    commit('setNotifications', response.data.total_count);

                    return resolve(response);
                })
                .catch(error => reject(error));
        });

    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
